var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticStyle: { display: "flex", "flex-direction": "column", gap: "10px" },
    },
    [
      !_vm.datasetId
        ? _c("div", { staticStyle: { color: "red", margin: "0 auto" } }, [
            _vm._v(" Please Select a Dataset "),
          ])
        : _vm._e(),
      _vm.datasetId && !_vm.dataLoaded
        ? _c("div", { staticStyle: { margin: "0 auto" } }, [
            _vm._v(" Loading " + _vm._s(_vm.preloadItem) + "... "),
          ])
        : _vm._e(),
      _vm.dataLoaded
        ? _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "flex-direction": "column",
                gap: "20px",
                width: "100%",
              },
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "flex-direction": "column",
                    gap: "10px",
                    margin: "0 0 10px 0",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "flex-direction": "column",
                      },
                    },
                    [
                      _c("strong", { staticStyle: { "font-size": "20px" } }, [
                        _vm._v(_vm._s(_vm.metadata["datasetName"])),
                      ]),
                      _c("em", [_vm._v(_vm._s(_vm.metadata["authors"] || ""))]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        gap: "20px",
                        background: "#f8f8f8",
                        padding: "20px",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "flex-direction": "column",
                            "border-right": "1px solid #ccc",
                            "padding-right": "20px",
                          },
                        },
                        [
                          _c("div", { staticStyle: { display: "flex" } }, [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "font-weight": "bold",
                                  "min-width": "100px",
                                },
                              },
                              [_vm._v("Species")]
                            ),
                            _c(
                              "div",
                              { staticStyle: { "min-width": "100px" } },
                              [_vm._v(_vm._s(_vm.metadata["species"]))]
                            ),
                          ]),
                          _c("div", { staticStyle: { display: "flex" } }, [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "font-weight": "bold",
                                  "min-width": "100px",
                                },
                              },
                              [_vm._v("Tissue")]
                            ),
                            _c(
                              "div",
                              { staticStyle: { "min-width": "100px" } },
                              [_vm._v(_vm._s(_vm.metadata["tissue"]))]
                            ),
                          ]),
                          _vm.metadata["depot"]
                            ? _c("div", { staticStyle: { display: "flex" } }, [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "font-weight": "bold",
                                      "min-width": "100px",
                                    },
                                  },
                                  [_vm._v("Depot")]
                                ),
                                _c(
                                  "div",
                                  { staticStyle: { "min-width": "100px" } },
                                  [_vm._v(_vm._s(_vm.metadata["depot"]))]
                                ),
                              ])
                            : _vm._e(),
                          _vm.metadata["depot2"]
                            ? _c("div", { staticStyle: { display: "flex" } }, [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "font-weight": "bold",
                                      "min-width": "100px",
                                    },
                                  },
                                  [_vm._v("Sub-Depot")]
                                ),
                                _c(
                                  "div",
                                  { staticStyle: { "min-width": "100px" } },
                                  [_vm._v(_vm._s(_vm.metadata["depot2"]))]
                                ),
                              ])
                            : _vm._e(),
                          _vm.metadata["totalCells"]
                            ? _c("div", { staticStyle: { display: "flex" } }, [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "font-weight": "bold",
                                      "min-width": "100px",
                                    },
                                  },
                                  [_vm._v("Cell Count")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "min-width": "100px",
                                      "text-align": "right",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.metadata[
                                          "totalCells"
                                        ]?.toLocaleString()
                                      )
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "max-height": "100px",
                            overflow: "scroll",
                            "text-overflow": "ellipsis",
                          },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.metadata["summary"] || "") + " "
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
              _vm.layout === 0 || _vm.layout === 2
                ? _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "flex-direction": "column",
                        gap: "20px",
                        "align-self": "center",
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticStyle: { display: "flex", gap: "5px" } },
                        [
                          _c("div", { staticClass: "tabs-group" }, [
                            _c(
                              "div",
                              {
                                staticClass: "tabs-wrapper",
                                staticStyle: { "align-self": "flex-end" },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "tab",
                                    class: `${
                                      _vm.isSelectedTab("a", "1")
                                        ? "selected"
                                        : ""
                                    }`,
                                    on: {
                                      click: function ($event) {
                                        return _vm.selectTab("a", "1")
                                      },
                                    },
                                  },
                                  [_vm._v(" Cell Composition ")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "tab",
                                    class: `${
                                      _vm.isSelectedTab("a", "2")
                                        ? "selected"
                                        : ""
                                    }`,
                                    on: {
                                      click: function ($event) {
                                        return _vm.selectTab("a", "2")
                                      },
                                    },
                                  },
                                  [_vm._v(" Gene Expression ")]
                                ),
                              ]
                            ),
                            _c("div", { staticClass: "tabs-section-wrapper" }, [
                              _vm.isSelectedTab("a", "1")
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "tab-section",
                                      staticStyle: {
                                        display: "flex",
                                        "flex-direction": "column",
                                        gap: "20px",
                                        border: "1px solid #ddd",
                                        padding: "20px",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            display: "flex",
                                            gap: "20px",
                                          },
                                        },
                                        [
                                          _vm.coordinates
                                            ? _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    display: "flex",
                                                    "flex-direction": "column",
                                                    width: "min-content",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        display: "flex",
                                                        "justify-content":
                                                          "space-between",
                                                        "align-items":
                                                          "baseline",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "strong",
                                                        {
                                                          staticStyle: {
                                                            "font-size": "16px",
                                                            margin: "0 0 5px",
                                                          },
                                                        },
                                                        [_vm._v("UMAP")]
                                                      ),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.coordinates.length.toLocaleString()
                                                          ) +
                                                          " cells "
                                                      ),
                                                    ]
                                                  ),
                                                  _c("research-umap-plot", {
                                                    attrs: {
                                                      sectionId: _vm.sectionId,
                                                      title: "",
                                                      points: _vm.coordinates,
                                                      colors:
                                                        _vm.cellCompositionVars[
                                                          "a"
                                                        ].umapColors,
                                                      fields: _vm.rawData,
                                                      cellTypeField:
                                                        _vm.cellTypeField,
                                                      colorByField:
                                                        _vm.cellCompositionVars[
                                                          "a"
                                                        ].colorByLabel,
                                                      hoverFields: [
                                                        "cell_label",
                                                        "Donor",
                                                      ],
                                                      highlightLabel:
                                                        _vm.cellCompositionVars[
                                                          "a"
                                                        ].highlightLabel,
                                                      highlightLabels:
                                                        _vm.cellCompositionVars[
                                                          "a"
                                                        ].highlightLabels,
                                                      width: 400,
                                                      labelSizePx: 28,
                                                      isLoading:
                                                        _vm.isLoadingData,
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.colorByOptions
                                            ? _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    display: "flex",
                                                    "flex-direction": "column",
                                                    "align-self": "flex-start",
                                                    width: "200px",
                                                    height: "400px",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "strong",
                                                    {
                                                      staticStyle: {
                                                        "font-size": "16px",
                                                        margin: "0 0 5px",
                                                      },
                                                    },
                                                    [_vm._v("Color By")]
                                                  ),
                                                  _c(
                                                    "research-single-cell-selector",
                                                    {
                                                      attrs: {
                                                        data: _vm.rawData[
                                                          "metadata_labels"
                                                        ],
                                                        layout: "dropdown",
                                                        colors: _vm.labelColors,
                                                        defaultLabel:
                                                          _vm
                                                            .cellCompositionVars[
                                                            "a"
                                                          ].colorByLabel,
                                                      },
                                                      on: {
                                                        "on-update": function (
                                                          $event
                                                        ) {
                                                          return _vm.handleSelectorUpdate(
                                                            $event,
                                                            "a",
                                                            "1"
                                                          )
                                                        },
                                                        "on-hover": function (
                                                          $event
                                                        ) {
                                                          return _vm.handleSelectorHover(
                                                            $event,
                                                            "a",
                                                            "1"
                                                          )
                                                        },
                                                      },
                                                    }
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            display: "flex",
                                            "flex-direction": "column",
                                            width: "620px",
                                            gap: "10px",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "font-size": "16px",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "font-weight": "bold",
                                                  },
                                                },
                                                [_vm._v("Cell Proportion")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "font-style": "italic",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.cellCompositionVars[
                                                        "a"
                                                      ].segmentByLabel
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" per "),
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "font-style": "italic",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.cellCompositionVars[
                                                        "a"
                                                      ].displayByLabel
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                display: "flex",
                                                gap: "20px",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "flex-grow": "1",
                                                  },
                                                },
                                                [
                                                  _c("div", [
                                                    _vm._v("Display"),
                                                  ]),
                                                  _c("div", [
                                                    _c(
                                                      "select",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm
                                                                .cellCompositionVars[
                                                                "a"
                                                              ].displayByLabel,
                                                            expression:
                                                              "cellCompositionVars['a'].displayByLabel",
                                                          },
                                                        ],
                                                        staticStyle: {
                                                          width: "100%",
                                                        },
                                                        on: {
                                                          change: [
                                                            function ($event) {
                                                              var $$selectedVal =
                                                                Array.prototype.filter
                                                                  .call(
                                                                    $event
                                                                      .target
                                                                      .options,
                                                                    function (
                                                                      o
                                                                    ) {
                                                                      return o.selected
                                                                    }
                                                                  )
                                                                  .map(
                                                                    function (
                                                                      o
                                                                    ) {
                                                                      var val =
                                                                        "_value" in
                                                                        o
                                                                          ? o._value
                                                                          : o.value
                                                                      return val
                                                                    }
                                                                  )
                                                              _vm.$set(
                                                                _vm
                                                                  .cellCompositionVars[
                                                                  "a"
                                                                ],
                                                                "displayByLabel",
                                                                $event.target
                                                                  .multiple
                                                                  ? $$selectedVal
                                                                  : $$selectedVal[0]
                                                              )
                                                            },
                                                            function ($event) {
                                                              return _vm.selectSegmentBy(
                                                                $event,
                                                                _vm
                                                                  .cellCompositionVars[
                                                                  "a"
                                                                ]
                                                                  .segmentByLabel,
                                                                "a"
                                                              )
                                                            },
                                                          ],
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "option",
                                                          {
                                                            attrs: {
                                                              value: "",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "-- Select --"
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._l(
                                                          _vm.colorByOptions,
                                                          function (option) {
                                                            return _c(
                                                              "option",
                                                              {
                                                                domProps: {
                                                                  value:
                                                                    option[
                                                                      "raw field"
                                                                    ],
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      option[
                                                                        "field label"
                                                                      ]
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                      ],
                                                      2
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "flex-grow": "1",
                                                  },
                                                },
                                                [
                                                  _c("div", [
                                                    _vm._v("Subset By"),
                                                  ]),
                                                  _c("div", [
                                                    _c(
                                                      "select",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm
                                                                .cellCompositionVars[
                                                                "a"
                                                              ].segmentByLabel,
                                                            expression:
                                                              "cellCompositionVars['a'].segmentByLabel",
                                                          },
                                                        ],
                                                        staticStyle: {
                                                          width: "100%",
                                                        },
                                                        on: {
                                                          change: [
                                                            function ($event) {
                                                              var $$selectedVal =
                                                                Array.prototype.filter
                                                                  .call(
                                                                    $event
                                                                      .target
                                                                      .options,
                                                                    function (
                                                                      o
                                                                    ) {
                                                                      return o.selected
                                                                    }
                                                                  )
                                                                  .map(
                                                                    function (
                                                                      o
                                                                    ) {
                                                                      var val =
                                                                        "_value" in
                                                                        o
                                                                          ? o._value
                                                                          : o.value
                                                                      return val
                                                                    }
                                                                  )
                                                              _vm.$set(
                                                                _vm
                                                                  .cellCompositionVars[
                                                                  "a"
                                                                ],
                                                                "segmentByLabel",
                                                                $event.target
                                                                  .multiple
                                                                  ? $$selectedVal
                                                                  : $$selectedVal[0]
                                                              )
                                                            },
                                                            function ($event) {
                                                              return _vm.selectSegmentBy(
                                                                _vm
                                                                  .cellCompositionVars[
                                                                  "a"
                                                                ]
                                                                  .displayByLabel,
                                                                $event,
                                                                "a"
                                                              )
                                                            },
                                                          ],
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "option",
                                                          {
                                                            attrs: {
                                                              value: "",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "-- Select --"
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._l(
                                                          _vm.colorByOptions,
                                                          function (option) {
                                                            return _c(
                                                              "option",
                                                              {
                                                                domProps: {
                                                                  value:
                                                                    option[
                                                                      "raw field"
                                                                    ],
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      option[
                                                                        "field label"
                                                                      ]
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                      ],
                                                      2
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                display: "flex",
                                                "flex-wrap": "wrap",
                                                "justify-content": "flex-end",
                                                "column-gap": "5px",
                                                margin: "0 15px 0 35px",
                                              },
                                            },
                                            _vm._l(
                                              _vm.labelColors[
                                                _vm.cellCompositionVars["a"]
                                                  .segmentByLabel
                                              ],
                                              function (color, label) {
                                                return _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      display: "flex",
                                                      gap: "2px",
                                                      "align-items": "center",
                                                      "flex-wrap": "nowrap",
                                                    },
                                                    style: `opacity:${
                                                      _vm.cellCompositionVars[
                                                        "a"
                                                      ].highlightLabel !== "" &&
                                                      _vm.cellCompositionVars[
                                                        "a"
                                                      ].highlightLabel !== label
                                                        ? _vm
                                                            .cellCompositionVars[
                                                            "a"
                                                          ].highlightLabels
                                                            .length > 0 &&
                                                          !_vm.cellCompositionVars[
                                                            "a"
                                                          ].highlightLabels.includes(
                                                            label
                                                          )
                                                          ? "0.25"
                                                          : "0.5"
                                                        : "1"
                                                    }`,
                                                    attrs: {
                                                      "data-label": label,
                                                    },
                                                    on: {
                                                      mouseover: function (
                                                        $event
                                                      ) {
                                                        return _vm.labelHover(
                                                          label,
                                                          "a"
                                                        )
                                                      },
                                                      mouseout: function (
                                                        $event
                                                      ) {
                                                        return _vm.labelHoverOut(
                                                          label,
                                                          "a"
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "colorize-option",
                                                        class:
                                                          _vm
                                                            .cellCompositionVars[
                                                            "a"
                                                          ].highlightLabels
                                                            .length === 0 ||
                                                          _vm.cellCompositionVars[
                                                            "a"
                                                          ].highlightLabels.includes(
                                                            label
                                                          )
                                                            ? "active"
                                                            : "",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.labelClick(
                                                              label,
                                                              "a"
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "svg",
                                                          {
                                                            attrs: {
                                                              viewBox:
                                                                "0 -0.5 17 17",
                                                              xmlns:
                                                                "http://www.w3.org/2000/svg",
                                                            },
                                                          },
                                                          [
                                                            _c("path", {
                                                              attrs: {
                                                                d: "M3 10.333C3 13.463 5.427 16 8.418 16 11.41 16 14 13.463 14 10.333 14 7.204 8.418 0 8.418 0S3 7.204 3 10.333Z",
                                                                fill: color,
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          "white-space":
                                                            "nowrap",
                                                          cursor: "default",
                                                        },
                                                        style: `opacity:${
                                                          _vm
                                                            .cellCompositionVars[
                                                            "a"
                                                          ].highlightLabel !==
                                                            "" &&
                                                          _vm
                                                            .cellCompositionVars[
                                                            "a"
                                                          ].highlightLabel !==
                                                            label
                                                            ? "0.5"
                                                            : "1"
                                                        }`,
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(label) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                          _c("research-stacked-bar-plot", {
                                            attrs: {
                                              data: _vm.cellCompositionVars["a"]
                                                .segmentByCounts2,
                                              primaryKey:
                                                _vm.cellCompositionVars["a"]
                                                  .displayByLabel,
                                              subsetKey:
                                                _vm.cellCompositionVars["a"]
                                                  .segmentByLabel,
                                              xAxisLabel:
                                                _vm.cellCompositionVars["a"]
                                                  .displayByLabel,
                                              yAxisLabel: "Count",
                                              highlightKey:
                                                _vm.cellCompositionVars["a"]
                                                  .highlightLabel,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.isSelectedTab("a", "2")
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "tab-section",
                                      staticStyle: {
                                        display: "flex",
                                        "flex-direction": "column",
                                        gap: "20px",
                                        border: "1px solid #ddd",
                                        padding: "20px",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            display: "flex",
                                            gap: "20px",
                                          },
                                        },
                                        [
                                          _vm.coordinates
                                            ? _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    display: "flex",
                                                    "flex-direction": "column",
                                                    width: "min-content",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        display: "flex",
                                                        "justify-content":
                                                          "space-between",
                                                        "align-items":
                                                          "baseline",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            "font-size": "16px",
                                                            margin: "0 0 5px",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                "font-weight":
                                                                  "bold",
                                                              },
                                                            },
                                                            [_vm._v("UMAP")]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                "font-style":
                                                                  "italic",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm
                                                                    .geneExpressionVars[
                                                                    "a"
                                                                  ].selectedGene
                                                                    ? `${_vm.geneExpressionVars["a"].selectedGene}`
                                                                    : ""
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.coordinates.length.toLocaleString()
                                                          ) +
                                                          " cells "
                                                      ),
                                                    ]
                                                  ),
                                                  _c("research-umap-plot", {
                                                    attrs: {
                                                      sectionId: _vm.sectionId,
                                                      title: "",
                                                      points: _vm.coordinates,
                                                      colors:
                                                        _vm.geneExpressionVars[
                                                          "a"
                                                        ].umapGeneColors,
                                                      fields: _vm.rawData,
                                                      cellTypeField:
                                                        _vm.cellTypeField,
                                                      colorByField:
                                                        _vm.cellCompositionVars[
                                                          "b"
                                                        ].colorByLabel,
                                                      hoverFields: [
                                                        "cell_label",
                                                        "Donor",
                                                      ],
                                                      expression:
                                                        _vm.expressionData[
                                                          _vm
                                                            .geneExpressionVars[
                                                            "a"
                                                          ].selectedGene
                                                        ],
                                                      expressionGene:
                                                        _vm.geneExpressionVars[
                                                          "a"
                                                        ].selectedGene,
                                                      highlightLabel:
                                                        _vm.cellCompositionVars[
                                                          "b"
                                                        ].highlightLabel,
                                                      highlightLabels:
                                                        _vm.cellCompositionVars[
                                                          "b"
                                                        ].highlightLabels,
                                                      width: 400,
                                                      labelSizePx: 28,
                                                      isLoading:
                                                        _vm.isLoadingData,
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                display: "flex",
                                                "flex-direction": "column",
                                                "align-self": "flex-start",
                                                width: "200px",
                                              },
                                            },
                                            [
                                              _c(
                                                "strong",
                                                {
                                                  staticStyle: {
                                                    "font-size": "16px",
                                                    margin: "0 0 5px",
                                                  },
                                                },
                                                [_vm._v("Gene Search")]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    display: "flex",
                                                    "flex-direction": "column",
                                                    height: "400px",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        display: "flex",
                                                        gap: "5px",
                                                      },
                                                    },
                                                    [
                                                      _c("input", {
                                                        staticStyle: {
                                                          width:
                                                            "-webkit-fill-available",
                                                        },
                                                        attrs: {
                                                          type: "text",
                                                          placeholder:
                                                            "Gene name",
                                                        },
                                                        on: {
                                                          keyup: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              !$event.type.indexOf(
                                                                "key"
                                                              ) &&
                                                              _vm._k(
                                                                $event.keyCode,
                                                                "enter",
                                                                13,
                                                                $event.key,
                                                                "Enter"
                                                              )
                                                            )
                                                              return null
                                                            return _vm.searchGene.apply(
                                                              null,
                                                              arguments
                                                            )
                                                          },
                                                        },
                                                      }),
                                                      _c(
                                                        "button",
                                                        {
                                                          on: {
                                                            click:
                                                              _vm.searchGene,
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "svg",
                                                            {
                                                              staticStyle: {
                                                                width: "20px",
                                                              },
                                                              attrs: {
                                                                viewBox:
                                                                  "0 0 24 24",
                                                                fill: "none",
                                                                xmlns:
                                                                  "http://www.w3.org/2000/svg",
                                                                stroke: "#000",
                                                              },
                                                            },
                                                            [
                                                              _c("path", {
                                                                attrs: {
                                                                  "fill-rule":
                                                                    "evenodd",
                                                                  "clip-rule":
                                                                    "evenodd",
                                                                  d: "M15 10.5a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0Zm-.82 4.74a6 6 0 1 1 1.06-1.06l4.79 4.79-1.06 1.06-4.79-4.79Z",
                                                                  fill: "#080341",
                                                                },
                                                              }),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _vm.geneNames.length > 0
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            "margin-top": "4px",
                                                            "flex-grow": "1",
                                                            "overflow-x":
                                                              "hidden",
                                                            "overflow-y":
                                                              "auto",
                                                          },
                                                        },
                                                        _vm._l(
                                                          _vm.geneNames,
                                                          function (gene) {
                                                            return _c(
                                                              "div",
                                                              {
                                                                staticStyle: {
                                                                  display:
                                                                    "flex",
                                                                  "flex-direction":
                                                                    "column",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        display:
                                                                          "flex",
                                                                        gap: "5px",
                                                                      },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        directives:
                                                                          [
                                                                            {
                                                                              name: "b-tooltip",
                                                                              rawName:
                                                                                "v-b-tooltip:hover.left.window",
                                                                              value:
                                                                                "color by gene",
                                                                              expression:
                                                                                "'color by gene'",
                                                                              arg: "hover",
                                                                              modifiers:
                                                                                {
                                                                                  left: true,
                                                                                  window: true,
                                                                                },
                                                                            },
                                                                          ],
                                                                        staticClass:
                                                                          "colorize-option",
                                                                        class:
                                                                          _vm
                                                                            .geneExpressionVars[
                                                                            "a"
                                                                          ]
                                                                            .selectedGene ===
                                                                          gene
                                                                            ? "active"
                                                                            : "",
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.geneClick(
                                                                                gene,
                                                                                "a"
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "svg",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                viewBox:
                                                                                  "0 -0.5 17 17",
                                                                                xmlns:
                                                                                  "http://www.w3.org/2000/svg",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "path",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    d: "M3 10.333C3 13.463 5.427 16 8.418 16 11.41 16 14 13.463 14 10.333 14 7.204 8.418 0 8.418 0S3 7.204 3 10.333Z",
                                                                                    fill: "#434343",
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c("div", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          gene
                                                                        )
                                                                      ),
                                                                    ]),
                                                                  ]
                                                                ),
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                        0
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c("div", [
                                        _vm.geneExpressionVars["a"]
                                          .expressionStats.length > 0
                                          ? _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  display: "flex",
                                                  "flex-direction": "column",
                                                  gap: "10px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      display: "flex",
                                                      "flex-direction":
                                                        "column",
                                                      gap: "10px",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          "font-size": "16px",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticStyle: {
                                                              "font-weight":
                                                                "bold",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Gene Expression"
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticStyle: {
                                                              "font-style":
                                                                "italic",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm
                                                                  .geneExpressionVars[
                                                                  "a"
                                                                ].selectedGene
                                                                  ? `${_vm.geneExpressionVars["a"].selectedGene}`
                                                                  : ""
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          display: "flex",
                                                          gap: "20px",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticStyle: {
                                                              display: "flex",
                                                              "flex-direction":
                                                                "column",
                                                              "align-items":
                                                                "baseline",
                                                              flex: "1",
                                                            },
                                                          },
                                                          [
                                                            _c("div", [
                                                              _vm._v("Display"),
                                                            ]),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticStyle: {
                                                                  width: "100%",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "select",
                                                                  {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "model",
                                                                          rawName:
                                                                            "v-model",
                                                                          value:
                                                                            _vm
                                                                              .geneExpressionVars[
                                                                              "a"
                                                                            ]
                                                                              .selectedLabel,
                                                                          expression:
                                                                            "geneExpressionVars['a'].selectedLabel",
                                                                        },
                                                                      ],
                                                                    staticStyle:
                                                                      {
                                                                        width:
                                                                          "100%",
                                                                      },
                                                                    on: {
                                                                      change: [
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          var $$selectedVal =
                                                                            Array.prototype.filter
                                                                              .call(
                                                                                $event
                                                                                  .target
                                                                                  .options,
                                                                                function (
                                                                                  o
                                                                                ) {
                                                                                  return o.selected
                                                                                }
                                                                              )
                                                                              .map(
                                                                                function (
                                                                                  o
                                                                                ) {
                                                                                  var val =
                                                                                    "_value" in
                                                                                    o
                                                                                      ? o._value
                                                                                      : o.value
                                                                                  return val
                                                                                }
                                                                              )
                                                                          _vm.$set(
                                                                            _vm
                                                                              .geneExpressionVars[
                                                                              "a"
                                                                            ],
                                                                            "selectedLabel",
                                                                            $event
                                                                              .target
                                                                              .multiple
                                                                              ? $$selectedVal
                                                                              : $$selectedVal[0]
                                                                          )
                                                                        },
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.selectExpressionBy(
                                                                            $event,
                                                                            "a"
                                                                          )
                                                                        },
                                                                      ],
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "option",
                                                                      {
                                                                        attrs: {
                                                                          value:
                                                                            "",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "-- Select --"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._l(
                                                                      _vm.colorByOptions,
                                                                      function (
                                                                        option
                                                                      ) {
                                                                        return _c(
                                                                          "option",
                                                                          {
                                                                            domProps:
                                                                              {
                                                                                value:
                                                                                  option[
                                                                                    "raw field"
                                                                                  ],
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  option[
                                                                                    "field label"
                                                                                  ]
                                                                                ) +
                                                                                " "
                                                                            ),
                                                                          ]
                                                                        )
                                                                      }
                                                                    ),
                                                                  ],
                                                                  2
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticStyle: {
                                                              display: "flex",
                                                              "flex-direction":
                                                                "column",
                                                              "align-items":
                                                                "baseline",
                                                              flex: "1",
                                                            },
                                                          },
                                                          [
                                                            _c("div", [
                                                              _vm._v(
                                                                "Subset by"
                                                              ),
                                                            ]),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticStyle: {
                                                                  width: "100%",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "select",
                                                                  {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "model",
                                                                          rawName:
                                                                            "v-model",
                                                                          value:
                                                                            _vm
                                                                              .geneExpressionVars[
                                                                              "a"
                                                                            ]
                                                                              .subsetLabel,
                                                                          expression:
                                                                            "geneExpressionVars['a'].subsetLabel",
                                                                        },
                                                                      ],
                                                                    staticStyle:
                                                                      {
                                                                        width:
                                                                          "100%",
                                                                      },
                                                                    on: {
                                                                      change: [
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          var $$selectedVal =
                                                                            Array.prototype.filter
                                                                              .call(
                                                                                $event
                                                                                  .target
                                                                                  .options,
                                                                                function (
                                                                                  o
                                                                                ) {
                                                                                  return o.selected
                                                                                }
                                                                              )
                                                                              .map(
                                                                                function (
                                                                                  o
                                                                                ) {
                                                                                  var val =
                                                                                    "_value" in
                                                                                    o
                                                                                      ? o._value
                                                                                      : o.value
                                                                                  return val
                                                                                }
                                                                              )
                                                                          _vm.$set(
                                                                            _vm
                                                                              .geneExpressionVars[
                                                                              "a"
                                                                            ],
                                                                            "subsetLabel",
                                                                            $event
                                                                              .target
                                                                              .multiple
                                                                              ? $$selectedVal
                                                                              : $$selectedVal[0]
                                                                          )
                                                                        },
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.selectExpressionBy(
                                                                            $event,
                                                                            "a"
                                                                          )
                                                                        },
                                                                      ],
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "option",
                                                                      {
                                                                        attrs: {
                                                                          value:
                                                                            "",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "-- Select --"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._l(
                                                                      _vm.colorByOptions,
                                                                      function (
                                                                        option
                                                                      ) {
                                                                        return _c(
                                                                          "option",
                                                                          {
                                                                            domProps:
                                                                              {
                                                                                value:
                                                                                  option[
                                                                                    "raw field"
                                                                                  ],
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  option[
                                                                                    "field label"
                                                                                  ]
                                                                                ) +
                                                                                " "
                                                                            ),
                                                                          ]
                                                                        )
                                                                      }
                                                                    ),
                                                                  ],
                                                                  2
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      display: "flex",
                                                      "flex-wrap": "wrap",
                                                      "justify-content":
                                                        "flex-end",
                                                      "column-gap": "5px",
                                                      margin: "0 15px 0 35px",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.labelColors[
                                                      _vm.geneExpressionVars[
                                                        "a"
                                                      ].subsetLabel
                                                    ],
                                                    function (color, label) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            display: "flex",
                                                            gap: "2px",
                                                            "align-items":
                                                              "center",
                                                            "flex-wrap":
                                                              "nowrap",
                                                          },
                                                          style: `opacity:${
                                                            _vm
                                                              .cellCompositionVars[
                                                              "b"
                                                            ].highlightLabel !==
                                                              "" &&
                                                            _vm
                                                              .cellCompositionVars[
                                                              "b"
                                                            ].highlightLabel !==
                                                              label
                                                              ? _vm
                                                                  .cellCompositionVars[
                                                                  "b"
                                                                ]
                                                                  .highlightLabels
                                                                  .length > 0 &&
                                                                !_vm.cellCompositionVars[
                                                                  "b"
                                                                ].highlightLabels.includes(
                                                                  label
                                                                )
                                                                ? "0.25"
                                                                : "0.5"
                                                              : "1"
                                                          }`,
                                                          attrs: {
                                                            "data-label": label,
                                                          },
                                                          on: {
                                                            mouseover:
                                                              function (
                                                                $event
                                                              ) {
                                                                return _vm.labelHover(
                                                                  label,
                                                                  "b"
                                                                )
                                                              },
                                                            mouseout: function (
                                                              $event
                                                            ) {
                                                              return _vm.labelHoverOut(
                                                                label,
                                                                "b"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "colorize-option",
                                                              class:
                                                                _vm
                                                                  .cellCompositionVars[
                                                                  "b"
                                                                ]
                                                                  .highlightLabels
                                                                  .length ===
                                                                  0 ||
                                                                _vm.cellCompositionVars[
                                                                  "b"
                                                                ].highlightLabels.includes(
                                                                  label
                                                                )
                                                                  ? "active"
                                                                  : "",
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.labelClick(
                                                                      label,
                                                                      "b"
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "svg",
                                                                {
                                                                  attrs: {
                                                                    viewBox:
                                                                      "0 -0.5 17 17",
                                                                    xmlns:
                                                                      "http://www.w3.org/2000/svg",
                                                                  },
                                                                },
                                                                [
                                                                  _c("path", {
                                                                    attrs: {
                                                                      d: "M3 10.333C3 13.463 5.427 16 8.418 16 11.41 16 14 13.463 14 10.333 14 7.204 8.418 0 8.418 0S3 7.204 3 10.333Z",
                                                                      fill: color,
                                                                    },
                                                                  }),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticStyle: {
                                                                "white-space":
                                                                  "nowrap",
                                                                cursor:
                                                                  "default",
                                                              },
                                                              style: `opacity:${
                                                                _vm
                                                                  .cellCompositionVars[
                                                                  "b"
                                                                ]
                                                                  .highlightLabel !==
                                                                  "" &&
                                                                _vm
                                                                  .cellCompositionVars[
                                                                  "b"
                                                                ]
                                                                  .highlightLabel !==
                                                                  label
                                                                  ? "0.5"
                                                                  : "1"
                                                              }`,
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    label
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                ),
                                                _c("research-violin-plot", {
                                                  attrs: {
                                                    data: _vm
                                                      .geneExpressionVars["a"]
                                                      .expressionStats,
                                                    primaryKey:
                                                      _vm.geneExpressionVars[
                                                        "a"
                                                      ].selectedLabel,
                                                    subsetKey:
                                                      _vm.geneExpressionVars[
                                                        "a"
                                                      ].subsetLabel,
                                                    highlightKey:
                                                      _vm.cellCompositionVars[
                                                        "b"
                                                      ].highlightLabel,
                                                    height: 300,
                                                    xAxisLabel:
                                                      "Log-Normalized Expression",
                                                    yAxisLabel: `${
                                                      _vm.geneExpressionVars[
                                                        "a"
                                                      ].selectedLabel
                                                    } ${
                                                      _vm.geneExpressionVars[
                                                        "a"
                                                      ].subsetLabel != ""
                                                        ? " x " +
                                                          _vm
                                                            .geneExpressionVars[
                                                            "a"
                                                          ].subsetLabel
                                                        : ""
                                                    }`,
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                            ]),
                          ]),
                          _c("div", { staticStyle: { padding: "4px 0 0 0" } }, [
                            _vm._v("vs"),
                          ]),
                          _c("div", { staticClass: "tabs-group" }, [
                            _c("div", { staticClass: "tabs-wrapper" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "tab",
                                  class: `${
                                    _vm.isSelectedTab("b", "1")
                                      ? "selected"
                                      : ""
                                  }`,
                                  on: {
                                    click: function ($event) {
                                      return _vm.selectTab("b", "1")
                                    },
                                  },
                                },
                                [_vm._v(" Cell Composition ")]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "tab",
                                  class: `${
                                    _vm.isSelectedTab("b", "2")
                                      ? "selected"
                                      : ""
                                  }`,
                                  on: {
                                    click: function ($event) {
                                      return _vm.selectTab("b", "2")
                                    },
                                  },
                                },
                                [_vm._v(" Gene Expression ")]
                              ),
                            ]),
                            _c("div", { staticClass: "tabs-section-wrapper" }, [
                              _vm.isSelectedTab("b", "1")
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "tab-section",
                                      staticStyle: {
                                        display: "flex",
                                        "flex-direction": "column",
                                        gap: "20px",
                                        border: "1px solid #ddd",
                                        padding: "20px",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            display: "flex",
                                            gap: "20px",
                                          },
                                        },
                                        [
                                          _vm.coordinates
                                            ? _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    display: "flex",
                                                    "flex-direction": "column",
                                                    width: "min-content",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        display: "flex",
                                                        "justify-content":
                                                          "space-between",
                                                        "align-items":
                                                          "baseline",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "strong",
                                                        {
                                                          staticStyle: {
                                                            "font-size": "16px",
                                                            margin: "0 0 5px",
                                                          },
                                                        },
                                                        [_vm._v("UMAP")]
                                                      ),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.coordinates.length.toLocaleString()
                                                          ) +
                                                          " cells "
                                                      ),
                                                    ]
                                                  ),
                                                  _c("research-umap-plot", {
                                                    attrs: {
                                                      sectionId: _vm.sectionId,
                                                      title: "",
                                                      points: _vm.coordinates,
                                                      colors:
                                                        _vm.cellCompositionVars[
                                                          "b"
                                                        ].umapColors,
                                                      fields: _vm.rawData,
                                                      cellTypeField:
                                                        _vm.cellTypeField,
                                                      colorByField:
                                                        _vm.cellCompositionVars[
                                                          "b"
                                                        ].colorByLabel,
                                                      hoverFields: [
                                                        "cell_label",
                                                        "Donor",
                                                      ],
                                                      highlightLabel:
                                                        _vm.cellCompositionVars[
                                                          "b"
                                                        ].highlightLabel,
                                                      highlightLabels:
                                                        _vm.cellCompositionVars[
                                                          "b"
                                                        ].highlightLabels,
                                                      width: 400,
                                                      labelSizePx: 28,
                                                      isLoading:
                                                        _vm.isLoadingData,
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.colorByOptions
                                            ? _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    display: "flex",
                                                    "flex-direction": "column",
                                                    "align-self": "flex-start",
                                                    width: "200px",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "strong",
                                                    {
                                                      staticStyle: {
                                                        "font-size": "16px",
                                                        margin: "0 0 5px",
                                                      },
                                                    },
                                                    [_vm._v("Color By")]
                                                  ),
                                                  _c(
                                                    "research-single-cell-selector",
                                                    {
                                                      attrs: {
                                                        data: _vm.rawData[
                                                          "metadata_labels"
                                                        ],
                                                        layout: "dropdown",
                                                        colors: _vm.labelColors,
                                                        defaultLabel:
                                                          _vm
                                                            .cellCompositionVars[
                                                            "b"
                                                          ].colorByLabel,
                                                      },
                                                      on: {
                                                        "on-update": function (
                                                          $event
                                                        ) {
                                                          return _vm.handleSelectorUpdate(
                                                            $event,
                                                            "b",
                                                            "1"
                                                          )
                                                        },
                                                        "on-hover": function (
                                                          $event
                                                        ) {
                                                          return _vm.handleSelectorHover(
                                                            $event,
                                                            "b",
                                                            "1"
                                                          )
                                                        },
                                                      },
                                                    }
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            display: "flex",
                                            "flex-direction": "column",
                                            width: "620px",
                                            gap: "10px",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "font-size": "16px",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "font-weight": "bold",
                                                  },
                                                },
                                                [_vm._v("Cell Proportion")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "font-style": "italic",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.cellCompositionVars[
                                                        "b"
                                                      ].segmentByLabel
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" per "),
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "font-style": "italic",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.cellCompositionVars[
                                                        "b"
                                                      ].displayByLabel
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                display: "flex",
                                                gap: "20px",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "flex-grow": "1",
                                                  },
                                                },
                                                [
                                                  _c("div", [
                                                    _vm._v("Display"),
                                                  ]),
                                                  _c("div", [
                                                    _c(
                                                      "select",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm
                                                                .cellCompositionVars[
                                                                "b"
                                                              ].displayByLabel,
                                                            expression:
                                                              "cellCompositionVars['b'].displayByLabel",
                                                          },
                                                        ],
                                                        staticStyle: {
                                                          width: "100%",
                                                        },
                                                        on: {
                                                          change: [
                                                            function ($event) {
                                                              var $$selectedVal =
                                                                Array.prototype.filter
                                                                  .call(
                                                                    $event
                                                                      .target
                                                                      .options,
                                                                    function (
                                                                      o
                                                                    ) {
                                                                      return o.selected
                                                                    }
                                                                  )
                                                                  .map(
                                                                    function (
                                                                      o
                                                                    ) {
                                                                      var val =
                                                                        "_value" in
                                                                        o
                                                                          ? o._value
                                                                          : o.value
                                                                      return val
                                                                    }
                                                                  )
                                                              _vm.$set(
                                                                _vm
                                                                  .cellCompositionVars[
                                                                  "b"
                                                                ],
                                                                "displayByLabel",
                                                                $event.target
                                                                  .multiple
                                                                  ? $$selectedVal
                                                                  : $$selectedVal[0]
                                                              )
                                                            },
                                                            function ($event) {
                                                              return _vm.selectSegmentBy(
                                                                $event,
                                                                _vm
                                                                  .cellCompositionVars[
                                                                  "b"
                                                                ]
                                                                  .segmentByLabel,
                                                                "b"
                                                              )
                                                            },
                                                          ],
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "option",
                                                          {
                                                            attrs: {
                                                              value: "",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "-- Select --"
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._l(
                                                          _vm.colorByOptions,
                                                          function (option) {
                                                            return _c(
                                                              "option",
                                                              {
                                                                domProps: {
                                                                  value:
                                                                    option[
                                                                      "raw field"
                                                                    ],
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      option[
                                                                        "field label"
                                                                      ]
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                      ],
                                                      2
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "flex-grow": "1",
                                                  },
                                                },
                                                [
                                                  _c("div", [
                                                    _vm._v("Subset By"),
                                                  ]),
                                                  _c("div", [
                                                    _c(
                                                      "select",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm
                                                                .cellCompositionVars[
                                                                "b"
                                                              ].segmentByLabel,
                                                            expression:
                                                              "cellCompositionVars['b'].segmentByLabel",
                                                          },
                                                        ],
                                                        staticStyle: {
                                                          width: "100%",
                                                        },
                                                        on: {
                                                          change: [
                                                            function ($event) {
                                                              var $$selectedVal =
                                                                Array.prototype.filter
                                                                  .call(
                                                                    $event
                                                                      .target
                                                                      .options,
                                                                    function (
                                                                      o
                                                                    ) {
                                                                      return o.selected
                                                                    }
                                                                  )
                                                                  .map(
                                                                    function (
                                                                      o
                                                                    ) {
                                                                      var val =
                                                                        "_value" in
                                                                        o
                                                                          ? o._value
                                                                          : o.value
                                                                      return val
                                                                    }
                                                                  )
                                                              _vm.$set(
                                                                _vm
                                                                  .cellCompositionVars[
                                                                  "b"
                                                                ],
                                                                "segmentByLabel",
                                                                $event.target
                                                                  .multiple
                                                                  ? $$selectedVal
                                                                  : $$selectedVal[0]
                                                              )
                                                            },
                                                            function ($event) {
                                                              return _vm.selectSegmentBy(
                                                                _vm
                                                                  .cellCompositionVars[
                                                                  "b"
                                                                ]
                                                                  .displayByLabel,
                                                                $event,
                                                                "b"
                                                              )
                                                            },
                                                          ],
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "option",
                                                          {
                                                            attrs: {
                                                              value: "",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "-- Select --"
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._l(
                                                          _vm.colorByOptions,
                                                          function (option) {
                                                            return _c(
                                                              "option",
                                                              {
                                                                domProps: {
                                                                  value:
                                                                    option[
                                                                      "raw field"
                                                                    ],
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      option[
                                                                        "field label"
                                                                      ]
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                      ],
                                                      2
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                display: "flex",
                                                "flex-wrap": "wrap",
                                                "justify-content": "flex-end",
                                                "column-gap": "5px",
                                                margin: "0 15px 0 35px",
                                              },
                                            },
                                            _vm._l(
                                              _vm.labelColors[
                                                _vm.cellCompositionVars["b"]
                                                  .segmentByLabel
                                              ],
                                              function (color, label) {
                                                return _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      display: "flex",
                                                      gap: "2px",
                                                      "align-items": "center",
                                                      "flex-wrap": "nowrap",
                                                    },
                                                    style: `opacity:${
                                                      _vm.cellCompositionVars[
                                                        "b"
                                                      ].highlightLabel !== "" &&
                                                      _vm.cellCompositionVars[
                                                        "b"
                                                      ].highlightLabel !== label
                                                        ? _vm
                                                            .cellCompositionVars[
                                                            "b"
                                                          ].highlightLabels
                                                            .length > 0 &&
                                                          !_vm.cellCompositionVars[
                                                            "b"
                                                          ].highlightLabels.includes(
                                                            label
                                                          )
                                                          ? "0.25"
                                                          : "0.5"
                                                        : "1"
                                                    }`,
                                                    attrs: {
                                                      "data-label": label,
                                                    },
                                                    on: {
                                                      mouseover: function (
                                                        $event
                                                      ) {
                                                        return _vm.labelHover(
                                                          label,
                                                          "b"
                                                        )
                                                      },
                                                      mouseout: function (
                                                        $event
                                                      ) {
                                                        return _vm.labelHoverOut(
                                                          label,
                                                          "b"
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "colorize-option",
                                                        class:
                                                          _vm
                                                            .cellCompositionVars[
                                                            "b"
                                                          ].highlightLabels
                                                            .length === 0 ||
                                                          _vm.cellCompositionVars[
                                                            "b"
                                                          ].highlightLabels.includes(
                                                            label
                                                          )
                                                            ? "active"
                                                            : "",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.labelClick(
                                                              label,
                                                              "b"
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "svg",
                                                          {
                                                            attrs: {
                                                              viewBox:
                                                                "0 -0.5 17 17",
                                                              xmlns:
                                                                "http://www.w3.org/2000/svg",
                                                            },
                                                          },
                                                          [
                                                            _c("path", {
                                                              attrs: {
                                                                d: "M3 10.333C3 13.463 5.427 16 8.418 16 11.41 16 14 13.463 14 10.333 14 7.204 8.418 0 8.418 0S3 7.204 3 10.333Z",
                                                                fill: color,
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          "white-space":
                                                            "nowrap",
                                                          cursor: "default",
                                                        },
                                                        style: `opacity:${
                                                          _vm
                                                            .cellCompositionVars[
                                                            "b"
                                                          ].highlightLabel !==
                                                            "" &&
                                                          _vm
                                                            .cellCompositionVars[
                                                            "b"
                                                          ].highlightLabel !==
                                                            label
                                                            ? "0.5"
                                                            : "1"
                                                        }`,
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(label) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                          _c("research-stacked-bar-plot", {
                                            attrs: {
                                              data: _vm.cellCompositionVars["b"]
                                                .segmentByCounts2,
                                              primaryKey:
                                                _vm.cellCompositionVars["b"]
                                                  .displayByLabel,
                                              subsetKey:
                                                _vm.cellCompositionVars["b"]
                                                  .segmentByLabel,
                                              xAxisLabel:
                                                _vm.cellCompositionVars["b"]
                                                  .displayByLabel,
                                              yAxisLabel: "Count",
                                              highlightKey:
                                                _vm.cellCompositionVars["b"]
                                                  .highlightLabel,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.isSelectedTab("b", "2")
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "tab-section",
                                      staticStyle: {
                                        display: "flex",
                                        "flex-direction": "column",
                                        gap: "20px",
                                        border: "1px solid #ddd",
                                        padding: "20px",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            display: "flex",
                                            gap: "20px",
                                          },
                                        },
                                        [
                                          _vm.coordinates
                                            ? _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    display: "flex",
                                                    "flex-direction": "column",
                                                    width: "min-content",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        display: "flex",
                                                        "justify-content":
                                                          "space-between",
                                                        "align-items":
                                                          "baseline",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            "font-size": "16px",
                                                            margin: "0 0 5px",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                "font-weight":
                                                                  "bold",
                                                              },
                                                            },
                                                            [_vm._v("UMAP")]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                "font-style":
                                                                  "italic",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm
                                                                    .geneExpressionVars[
                                                                    "b"
                                                                  ].selectedGene
                                                                    ? `${_vm.geneExpressionVars["b"].selectedGene}`
                                                                    : ""
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.coordinates.length.toLocaleString()
                                                          ) +
                                                          " cells "
                                                      ),
                                                    ]
                                                  ),
                                                  _c("research-umap-plot", {
                                                    attrs: {
                                                      sectionId: _vm.sectionId,
                                                      title: "",
                                                      points: _vm.coordinates,
                                                      colors:
                                                        _vm.geneExpressionVars[
                                                          "b"
                                                        ].umapGeneColors,
                                                      fields: _vm.rawData,
                                                      cellTypeField:
                                                        _vm.cellTypeField,
                                                      colorByField:
                                                        _vm.cellCompositionVars[
                                                          "a"
                                                        ].colorByLabel,
                                                      hoverFields: [
                                                        "cell_label",
                                                        "Donor",
                                                      ],
                                                      expression:
                                                        _vm.expressionData[
                                                          _vm
                                                            .geneExpressionVars[
                                                            "b"
                                                          ].selectedGene
                                                        ],
                                                      expressionGene:
                                                        _vm.geneExpressionVars[
                                                          "b"
                                                        ].selectedGene,
                                                      highlightLabel:
                                                        _vm.cellCompositionVars[
                                                          "a"
                                                        ].highlightLabel,
                                                      highlightLabels:
                                                        _vm.cellCompositionVars[
                                                          "a"
                                                        ].highlightLabels,
                                                      width: 400,
                                                      labelSizePx: 28,
                                                      isLoading:
                                                        _vm.isLoadingData,
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                display: "flex",
                                                "flex-direction": "column",
                                                "align-self": "flex-start",
                                                width: "200px",
                                              },
                                            },
                                            [
                                              _c(
                                                "strong",
                                                {
                                                  staticStyle: {
                                                    "font-size": "16px",
                                                    margin: "0 0 5px",
                                                  },
                                                },
                                                [_vm._v("Gene Search")]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    display: "flex",
                                                    "flex-direction": "column",
                                                    height: "400px",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        display: "flex",
                                                        gap: "5px",
                                                      },
                                                    },
                                                    [
                                                      _c("input", {
                                                        staticStyle: {
                                                          width:
                                                            "-webkit-fill-available",
                                                        },
                                                        attrs: {
                                                          type: "text",
                                                          placeholder:
                                                            "Gene name",
                                                        },
                                                        on: {
                                                          keyup: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              !$event.type.indexOf(
                                                                "key"
                                                              ) &&
                                                              _vm._k(
                                                                $event.keyCode,
                                                                "enter",
                                                                13,
                                                                $event.key,
                                                                "Enter"
                                                              )
                                                            )
                                                              return null
                                                            return _vm.searchGene.apply(
                                                              null,
                                                              arguments
                                                            )
                                                          },
                                                        },
                                                      }),
                                                      _c(
                                                        "button",
                                                        {
                                                          on: {
                                                            click:
                                                              _vm.searchGene,
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "svg",
                                                            {
                                                              staticStyle: {
                                                                width: "20px",
                                                              },
                                                              attrs: {
                                                                viewBox:
                                                                  "0 0 24 24",
                                                                fill: "none",
                                                                xmlns:
                                                                  "http://www.w3.org/2000/svg",
                                                                stroke: "#000",
                                                              },
                                                            },
                                                            [
                                                              _c("path", {
                                                                attrs: {
                                                                  "fill-rule":
                                                                    "evenodd",
                                                                  "clip-rule":
                                                                    "evenodd",
                                                                  d: "M15 10.5a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0Zm-.82 4.74a6 6 0 1 1 1.06-1.06l4.79 4.79-1.06 1.06-4.79-4.79Z",
                                                                  fill: "#080341",
                                                                },
                                                              }),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _vm.geneNames.length > 0
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            "margin-top": "4px",
                                                            "flex-grow": "1",
                                                            "overflow-x":
                                                              "hidden",
                                                            "overflow-y":
                                                              "auto",
                                                          },
                                                        },
                                                        _vm._l(
                                                          _vm.geneNames,
                                                          function (gene) {
                                                            return _c(
                                                              "div",
                                                              {
                                                                staticStyle: {
                                                                  display:
                                                                    "flex",
                                                                  "flex-direction":
                                                                    "column",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        display:
                                                                          "flex",
                                                                        gap: "5px",
                                                                      },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        directives:
                                                                          [
                                                                            {
                                                                              name: "b-tooltip",
                                                                              rawName:
                                                                                "v-b-tooltip:hover.left.window",
                                                                              value:
                                                                                "color by gene",
                                                                              expression:
                                                                                "'color by gene'",
                                                                              arg: "hover",
                                                                              modifiers:
                                                                                {
                                                                                  left: true,
                                                                                  window: true,
                                                                                },
                                                                            },
                                                                          ],
                                                                        staticClass:
                                                                          "colorize-option",
                                                                        class:
                                                                          _vm
                                                                            .geneExpressionVars[
                                                                            "b"
                                                                          ]
                                                                            .selectedGene ===
                                                                          gene
                                                                            ? "active"
                                                                            : "",
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.geneClick(
                                                                                gene,
                                                                                "b"
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "svg",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                viewBox:
                                                                                  "0 -0.5 17 17",
                                                                                xmlns:
                                                                                  "http://www.w3.org/2000/svg",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "path",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    d: "M3 10.333C3 13.463 5.427 16 8.418 16 11.41 16 14 13.463 14 10.333 14 7.204 8.418 0 8.418 0S3 7.204 3 10.333Z",
                                                                                    fill: "#434343",
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c("div", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          gene
                                                                        )
                                                                      ),
                                                                    ]),
                                                                  ]
                                                                ),
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                        0
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c("div", [
                                        _vm.geneExpressionVars["b"]
                                          .expressionStats.length > 0
                                          ? _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  display: "flex",
                                                  "flex-direction": "column",
                                                  gap: "10px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      display: "flex",
                                                      "flex-direction":
                                                        "column",
                                                      gap: "10px",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          "font-size": "16px",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticStyle: {
                                                              "font-weight":
                                                                "bold",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Gene Expression"
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticStyle: {
                                                              "font-style":
                                                                "italic",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm
                                                                  .geneExpressionVars[
                                                                  "b"
                                                                ].selectedGene
                                                                  ? `${_vm.geneExpressionVars["b"].selectedGene}`
                                                                  : ""
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          display: "flex",
                                                          gap: "20px",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticStyle: {
                                                              display: "flex",
                                                              "flex-direction":
                                                                "column",
                                                              "align-items":
                                                                "baseline",
                                                              flex: "1",
                                                            },
                                                          },
                                                          [
                                                            _c("div", [
                                                              _vm._v("Display"),
                                                            ]),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticStyle: {
                                                                  width: "100%",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "select",
                                                                  {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "model",
                                                                          rawName:
                                                                            "v-model",
                                                                          value:
                                                                            _vm
                                                                              .geneExpressionVars[
                                                                              "b"
                                                                            ]
                                                                              .selectedLabel,
                                                                          expression:
                                                                            "geneExpressionVars['b'].selectedLabel",
                                                                        },
                                                                      ],
                                                                    staticStyle:
                                                                      {
                                                                        width:
                                                                          "100%",
                                                                      },
                                                                    on: {
                                                                      change: [
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          var $$selectedVal =
                                                                            Array.prototype.filter
                                                                              .call(
                                                                                $event
                                                                                  .target
                                                                                  .options,
                                                                                function (
                                                                                  o
                                                                                ) {
                                                                                  return o.selected
                                                                                }
                                                                              )
                                                                              .map(
                                                                                function (
                                                                                  o
                                                                                ) {
                                                                                  var val =
                                                                                    "_value" in
                                                                                    o
                                                                                      ? o._value
                                                                                      : o.value
                                                                                  return val
                                                                                }
                                                                              )
                                                                          _vm.$set(
                                                                            _vm
                                                                              .geneExpressionVars[
                                                                              "b"
                                                                            ],
                                                                            "selectedLabel",
                                                                            $event
                                                                              .target
                                                                              .multiple
                                                                              ? $$selectedVal
                                                                              : $$selectedVal[0]
                                                                          )
                                                                        },
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.selectExpressionBy(
                                                                            $event,
                                                                            "b"
                                                                          )
                                                                        },
                                                                      ],
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "option",
                                                                      {
                                                                        attrs: {
                                                                          value:
                                                                            "",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "-- Select --"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._l(
                                                                      _vm.colorByOptions,
                                                                      function (
                                                                        option
                                                                      ) {
                                                                        return _c(
                                                                          "option",
                                                                          {
                                                                            domProps:
                                                                              {
                                                                                value:
                                                                                  option[
                                                                                    "raw field"
                                                                                  ],
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  option[
                                                                                    "field label"
                                                                                  ]
                                                                                ) +
                                                                                " "
                                                                            ),
                                                                          ]
                                                                        )
                                                                      }
                                                                    ),
                                                                  ],
                                                                  2
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticStyle: {
                                                              display: "flex",
                                                              "flex-direction":
                                                                "column",
                                                              "align-items":
                                                                "baseline",
                                                              flex: "1",
                                                            },
                                                          },
                                                          [
                                                            _c("div", [
                                                              _vm._v(
                                                                "Subset by"
                                                              ),
                                                            ]),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticStyle: {
                                                                  width: "100%",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "select",
                                                                  {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "model",
                                                                          rawName:
                                                                            "v-model",
                                                                          value:
                                                                            _vm
                                                                              .geneExpressionVars[
                                                                              "b"
                                                                            ]
                                                                              .subsetLabel,
                                                                          expression:
                                                                            "geneExpressionVars['b'].subsetLabel",
                                                                        },
                                                                      ],
                                                                    staticStyle:
                                                                      {
                                                                        width:
                                                                          "100%",
                                                                      },
                                                                    on: {
                                                                      change: [
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          var $$selectedVal =
                                                                            Array.prototype.filter
                                                                              .call(
                                                                                $event
                                                                                  .target
                                                                                  .options,
                                                                                function (
                                                                                  o
                                                                                ) {
                                                                                  return o.selected
                                                                                }
                                                                              )
                                                                              .map(
                                                                                function (
                                                                                  o
                                                                                ) {
                                                                                  var val =
                                                                                    "_value" in
                                                                                    o
                                                                                      ? o._value
                                                                                      : o.value
                                                                                  return val
                                                                                }
                                                                              )
                                                                          _vm.$set(
                                                                            _vm
                                                                              .geneExpressionVars[
                                                                              "b"
                                                                            ],
                                                                            "subsetLabel",
                                                                            $event
                                                                              .target
                                                                              .multiple
                                                                              ? $$selectedVal
                                                                              : $$selectedVal[0]
                                                                          )
                                                                        },
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.selectExpressionBy(
                                                                            $event,
                                                                            "b"
                                                                          )
                                                                        },
                                                                      ],
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "option",
                                                                      {
                                                                        attrs: {
                                                                          value:
                                                                            "",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "-- Select --"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._l(
                                                                      _vm.colorByOptions,
                                                                      function (
                                                                        option
                                                                      ) {
                                                                        return _c(
                                                                          "option",
                                                                          {
                                                                            domProps:
                                                                              {
                                                                                value:
                                                                                  option[
                                                                                    "raw field"
                                                                                  ],
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  option[
                                                                                    "field label"
                                                                                  ]
                                                                                ) +
                                                                                " "
                                                                            ),
                                                                          ]
                                                                        )
                                                                      }
                                                                    ),
                                                                  ],
                                                                  2
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      display: "flex",
                                                      "flex-wrap": "wrap",
                                                      "justify-content":
                                                        "flex-end",
                                                      "column-gap": "5px",
                                                      margin: "0 15px 0 35px",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.labelColors[
                                                      _vm.geneExpressionVars[
                                                        "b"
                                                      ].subsetLabel
                                                    ],
                                                    function (color, label) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            display: "flex",
                                                            gap: "2px",
                                                            "align-items":
                                                              "center",
                                                            "flex-wrap":
                                                              "nowrap",
                                                          },
                                                          style: `opacity:${
                                                            _vm
                                                              .cellCompositionVars[
                                                              "a"
                                                            ].highlightLabel !==
                                                              "" &&
                                                            _vm
                                                              .cellCompositionVars[
                                                              "a"
                                                            ].highlightLabel !==
                                                              label
                                                              ? _vm
                                                                  .cellCompositionVars[
                                                                  "a"
                                                                ]
                                                                  .highlightLabels
                                                                  .length > 0 &&
                                                                !_vm.cellCompositionVars[
                                                                  "a"
                                                                ].highlightLabels.includes(
                                                                  label
                                                                )
                                                                ? "0.25"
                                                                : "0.5"
                                                              : "1"
                                                          }`,
                                                          attrs: {
                                                            "data-label": label,
                                                          },
                                                          on: {
                                                            mouseover:
                                                              function (
                                                                $event
                                                              ) {
                                                                return _vm.labelHover(
                                                                  label,
                                                                  "a"
                                                                )
                                                              },
                                                            mouseout: function (
                                                              $event
                                                            ) {
                                                              return _vm.labelHoverOut(
                                                                label,
                                                                "a"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "colorize-option",
                                                              class:
                                                                _vm
                                                                  .cellCompositionVars[
                                                                  "a"
                                                                ]
                                                                  .highlightLabels
                                                                  .length ===
                                                                  0 ||
                                                                _vm.cellCompositionVars[
                                                                  "a"
                                                                ].highlightLabels.includes(
                                                                  label
                                                                )
                                                                  ? "active"
                                                                  : "",
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.labelClick(
                                                                      label,
                                                                      "a"
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "svg",
                                                                {
                                                                  attrs: {
                                                                    viewBox:
                                                                      "0 -0.5 17 17",
                                                                    xmlns:
                                                                      "http://www.w3.org/2000/svg",
                                                                  },
                                                                },
                                                                [
                                                                  _c("path", {
                                                                    attrs: {
                                                                      d: "M3 10.333C3 13.463 5.427 16 8.418 16 11.41 16 14 13.463 14 10.333 14 7.204 8.418 0 8.418 0S3 7.204 3 10.333Z",
                                                                      fill: color,
                                                                    },
                                                                  }),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticStyle: {
                                                                "white-space":
                                                                  "nowrap",
                                                                cursor:
                                                                  "default",
                                                              },
                                                              style: `opacity:${
                                                                _vm
                                                                  .cellCompositionVars[
                                                                  "a"
                                                                ]
                                                                  .highlightLabel !==
                                                                  "" &&
                                                                _vm
                                                                  .cellCompositionVars[
                                                                  "a"
                                                                ]
                                                                  .highlightLabel !==
                                                                  label
                                                                  ? "0.5"
                                                                  : "1"
                                                              }`,
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    label
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                ),
                                                _c("research-violin-plot", {
                                                  attrs: {
                                                    data: _vm
                                                      .geneExpressionVars["b"]
                                                      .expressionStats,
                                                    primaryKey:
                                                      _vm.geneExpressionVars[
                                                        "b"
                                                      ].selectedLabel,
                                                    subsetKey:
                                                      _vm.geneExpressionVars[
                                                        "b"
                                                      ].subsetLabel,
                                                    highlightKey:
                                                      _vm.cellCompositionVars[
                                                        "a"
                                                      ].highlightLabel,
                                                    height: 300,
                                                    xAxisLabel:
                                                      "Log-Normalized Expression",
                                                    yAxisLabel: `${
                                                      _vm.geneExpressionVars[
                                                        "b"
                                                      ].selectedLabel
                                                    } ${
                                                      _vm.geneExpressionVars[
                                                        "b"
                                                      ].subsetLabel != ""
                                                        ? " x " +
                                                          _vm
                                                            .geneExpressionVars[
                                                            "b"
                                                          ].subsetLabel
                                                        : ""
                                                    }`,
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                            ]),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex", gap: "25px" } },
                        [
                          _vm.showMarkerGenes
                            ? _c(
                                "div",
                                {
                                  staticStyle: {
                                    width: "100%",
                                    display: "flex",
                                    "flex-direction": "column",
                                    gap: "20px",
                                    border: "1px solid #ddd",
                                    padding: "20px",
                                  },
                                },
                                [
                                  _vm.expressionStatsAll.length > 0
                                    ? _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            display: "flex",
                                            "flex-direction": "column",
                                            gap: "20px",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                display: "flex",
                                                "justify-content":
                                                  "space-between",
                                              },
                                            },
                                            [
                                              _c(
                                                "strong",
                                                {
                                                  staticStyle: {
                                                    "font-size": "16px",
                                                    margin: "0 0 5px",
                                                  },
                                                },
                                                [_vm._v("Marker Genes")]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "legends",
                                                  staticStyle: {
                                                    display: "flex",
                                                    gap: "5px",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "legend",
                                                      staticStyle: {
                                                        display: "flex",
                                                        "flex-direction":
                                                          "column",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass: "label",
                                                        },
                                                        [_vm._v("Expression")]
                                                      ),
                                                      _c("div", {
                                                        staticClass: "gradient",
                                                        style: `background: linear-gradient(to left, ${_vm.colorScalePlasmaColorsArray});`,
                                                      }),
                                                      _vm._m(0),
                                                    ]
                                                  ),
                                                  _vm._m(1),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c("research-dot-plot", {
                                            staticStyle: {
                                              display: "flex",
                                              "align-self": "center",
                                            },
                                            attrs: {
                                              data: _vm.expressionStatsAll,
                                              geneKey: "gene",
                                              primaryKey: this.cellTypeField,
                                              orientation: "horizontal",
                                              width: 620,
                                              fitToSize: true,
                                              cellWidth: 40,
                                              showYLabels: true,
                                              showXLabels: true,
                                              positionXLabelsOnTop: false,
                                              positionYLabelsOnRight: false,
                                              marginBottom: 50,
                                              marginLeft: -20,
                                              marginTop: 10,
                                              marginRight: 10,
                                              highlightKey: "",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              )
                            : _vm._e(),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.layout === 1
                ? _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        width: "100%",
                        "align-self": "center",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            gap: "40px",
                            flex: "1",
                          },
                        },
                        [
                          _c("research-single-cell-selector", {
                            attrs: {
                              data: _vm.rawData["metadata_labels"],
                              layout: "dropdown",
                              showSelect: false,
                              colors: _vm.labelColors,
                              defaultLabel:
                                _vm.cellCompositionVars["a"].colorByLabel,
                            },
                            on: {
                              "on-update": function ($event) {
                                return _vm.handleSelectorUpdate(
                                  $event,
                                  "a",
                                  "1"
                                )
                              },
                              "on-hover": function ($event) {
                                return _vm.handleSelectorHover($event, "a", "1")
                              },
                            },
                          }),
                          _c(
                            "div",
                            { staticStyle: { display: "flex", gap: "20px" } },
                            [
                              _vm.coordinates
                                ? _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "flex-direction": "column",
                                        width: "min-content",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            display: "flex",
                                            "justify-content": "space-between",
                                            "align-items": "baseline",
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "font-size": "16px",
                                                margin: "0 0 5px",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "font-weight": "bold",
                                                  },
                                                },
                                                [_vm._v("UMAP")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "font-style": "italic",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.geneExpressionVars[
                                                        "b"
                                                      ].selectedGene
                                                        ? `${_vm.geneExpressionVars["b"].selectedGene}`
                                                        : ""
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.coordinates.length.toLocaleString()
                                              ) +
                                              " cells "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            display: "flex",
                                            position: "relative",
                                          },
                                        },
                                        [
                                          _c("research-umap-plot", {
                                            attrs: {
                                              sectionId: _vm.sectionId,
                                              title: "",
                                              points: _vm.coordinates,
                                              colors:
                                                _vm.geneExpressionVars["b"]
                                                  .umapGeneColors,
                                              fields: _vm.rawData,
                                              cellTypeField: _vm.cellTypeField,
                                              colorByField:
                                                _vm.cellCompositionVars["a"]
                                                  .colorByLabel,
                                              hoverFields: ["cell_label"],
                                              expression:
                                                _vm.expressionData[
                                                  _vm.geneExpressionVars["b"]
                                                    .selectedGene
                                                ],
                                              expressionGene:
                                                _vm.geneExpressionVars["b"]
                                                  .selectedGene,
                                              highlightLabel:
                                                _vm.cellCompositionVars["a"]
                                                  .highlightLabel,
                                              highlightLabels:
                                                _vm.cellCompositionVars["a"]
                                                  .highlightLabels,
                                              width: 400,
                                              labelSizePx: 28,
                                              isLoading: _vm.isLoadingData,
                                            },
                                          }),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "legend",
                                              staticStyle: {
                                                display: "flex",
                                                "flex-direction": "column",
                                                position: "absolute",
                                                top: "4px",
                                                left: "5px",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "label" },
                                                [_vm._v("Expression")]
                                              ),
                                              _c("div", {
                                                staticClass: "gradient",
                                                style: `background: linear-gradient(to left, ${_vm.colorScalePlasmaColorsArray}); height:5px;`,
                                              }),
                                              _vm._m(2),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                          _vm.geneExpressionVars["b"].expressionStats.length > 0
                            ? _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "flex-direction": "column",
                                    flex: "1",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "flex-direction": "column",
                                        gap: "5px",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: { "font-size": "16px" },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "font-weight": "bold",
                                              },
                                            },
                                            [_vm._v("Gene Expression")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "font-style": "italic",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.geneExpressionVars["b"]
                                                    .selectedGene
                                                    ? `${_vm.geneExpressionVars["b"].selectedGene}`
                                                    : ""
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c("research-violin-plot", {
                                    attrs: {
                                      data: _vm.geneExpressionVars["b"]
                                        .expressionStats,
                                      primaryKey:
                                        _vm.geneExpressionVars["b"]
                                          .selectedLabel,
                                      subsetKey:
                                        _vm.geneExpressionVars["b"].subsetLabel,
                                      highlightKey:
                                        _vm.cellCompositionVars["a"]
                                          .highlightLabel,
                                      height: 400,
                                      xAxisLabel: "Log-Normalized Expression",
                                      yAxisLabel:
                                        _vm.geneExpressionVars["b"]
                                          .selectedLabel,
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      { staticClass: "marks", staticStyle: { display: "flex" } },
      [_c("div", [_vm._v("0.0")]), _c("div", [_vm._v("3.0")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      {
        staticClass: "legend",
        staticStyle: { display: "flex", "flex-direction": "column" },
      },
      [
        _c("div", { staticClass: "label" }, [_vm._v("% Cells Expressing")]),
        _c(
          "div",
          { staticClass: "circles", staticStyle: { display: "flex" } },
          [
            _c("div", { staticClass: "circleBorder" }, [
              _c("div", {
                staticClass: "circle",
                staticStyle: { height: "20%" },
              }),
            ]),
            _c("div", { staticClass: "circleBorder" }, [
              _c("div", {
                staticClass: "circle",
                staticStyle: { height: "40%" },
              }),
            ]),
            _c("div", { staticClass: "circleBorder" }, [
              _c("div", {
                staticClass: "circle",
                staticStyle: { height: "60%" },
              }),
            ]),
            _c("div", { staticClass: "circleBorder" }, [
              _c("div", {
                staticClass: "circle",
                staticStyle: { height: "80%" },
              }),
            ]),
            _c("div", { staticClass: "circleBorder" }, [
              _c("div", {
                staticClass: "circle",
                staticStyle: { height: "100%" },
              }),
            ]),
          ]
        ),
        _c("div", { staticClass: "marks", staticStyle: { display: "flex" } }, [
          _c("div", [_vm._v("0")]),
          _c("div", [_vm._v("100")]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      { staticClass: "marks", staticStyle: { display: "flex" } },
      [_c("div", [_vm._v("0.0")]), _c("div", [_vm._v("3.0")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }