var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "research-data-table-wrapper",
      class:
        !!_vm.tableFormat.display && _vm.tableFormat.display == "false"
          ? "hidden"
          : "",
    },
    [
      !!_vm.dataset
        ? _c("div", {
            staticClass: "data-table-legend",
            domProps: { innerHTML: _vm._s(_vm.tableLegend) },
          })
        : _vm._e(),
      !!_vm.searchParameters &&
      _vm.dataComparisonConfig != null &&
      _vm.compareGroups.length > 1
        ? _c(
            "div",
            { staticClass: "table-total-rows" },
            _vm._l(_vm.compareGroups, function (item, itemIndex) {
              return _c("span", {
                key: item,
                class: "group-item-bubble reference bg-color-" + itemIndex,
                domProps: { innerHTML: _vm._s(item) },
              })
            }),
            0
          )
        : _vm._e(),
      !!_vm.tableFormat["summary plot"] &&
      _vm.tableFormat["summary plot"]["plots"].includes("table")
        ? _c("research-summary-plot", {
            attrs: {
              summaryPlot: _vm.tableFormat["summary plot"],
              rawData: _vm.dataset,
              isPlotByRow: false,
            },
          })
        : _vm._e(),
      !!_vm.dataset
        ? _c("div", { staticClass: "table-ui-wrapper" }, [
            _c(
              "div",
              { staticClass: "convert-2-csv table-settings-opener btn-sm" },
              [
                _c(
                  "span",
                  { staticClass: "btn btn-default options-gear" },
                  [
                    _vm._v("Save data / set table "),
                    _c("b-icon", { attrs: { icon: "gear-fill" } }),
                  ],
                  1
                ),
              ]
            ),
            !!_vm.tableFormat
              ? _c(
                  "div",
                  {
                    staticClass: "table-options-box",
                    attrs: { id: "showHideColumnsBox" + _vm.sectionId },
                  },
                  [
                    _c("div", { staticClass: "table-wrapper" }, [
                      _c("table", { staticClass: "table table-sm" }, [
                        _c(
                          "tbody",
                          [
                            _c("tr", [
                              _c("td", [
                                _c("label", [
                                  _c("strong", [_vm._v("Rows per page: ")]),
                                  _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.perPageNumber,
                                          expression: "perPageNumber",
                                        },
                                      ],
                                      staticClass: "number-per-page",
                                      on: {
                                        change: function ($event) {
                                          var $$selectedVal =
                                            Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function (o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function (o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                          _vm.perPageNumber = $event.target
                                            .multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        },
                                      },
                                    },
                                    [
                                      _c("option", { attrs: { value: "10" } }, [
                                        _vm._v("10"),
                                      ]),
                                      _c("option", { attrs: { value: "20" } }, [
                                        _vm._v("20"),
                                      ]),
                                      _c("option", { attrs: { value: "40" } }, [
                                        _vm._v("40"),
                                      ]),
                                      _c(
                                        "option",
                                        { attrs: { value: "100" } },
                                        [_vm._v("100")]
                                      ),
                                      _vm._m(0),
                                    ]
                                  ),
                                ]),
                              ]),
                            ]),
                            _c("tr", [
                              _c("td", [
                                _c("strong", [
                                  _vm._v("Save data in section: "),
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticClass: "convert-2-csv btn-sm",
                                    on: {
                                      click: function ($event) {
                                        return _vm.convertJson2Csv(
                                          _vm.filteredData,
                                          _vm.pageID +
                                            _vm.sectionId +
                                            "_filtered"
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(" CSV ")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "convert-2-csv btn-sm",
                                    on: {
                                      click: function ($event) {
                                        return _vm.convertJson2Tsv(
                                          _vm.filteredData,
                                          _vm.pageID +
                                            _vm.sectionId +
                                            "_filtered"
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(" TSV ")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "convert-2-csv btn-sm",
                                    on: {
                                      click: function ($event) {
                                        return _vm.saveJson(
                                          _vm.filteredData,
                                          _vm.pageID +
                                            _vm.sectionId +
                                            "_filtered"
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(" JSON ")]
                                ),
                              ]),
                            ]),
                            _c("tr", [
                              _c(
                                "td",
                                [
                                  _c("Strong", [_vm._v("Hide table: ")]),
                                  _c("input", {
                                    staticClass: "chkbox",
                                    attrs: { type: "checkbox" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.utils.uiUtils.showHideElement(
                                          _vm.sectionId + "_table_wrapper"
                                        )
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _vm._m(1),
                            _vm._l(
                              _vm.tableFormat["top rows"],
                              function (column) {
                                return _c("tr", { key: column }, [
                                  _c("td", [
                                    _c("input", {
                                      attrs: {
                                        type: "checkbox",
                                        name: "visible_top_rows",
                                        id: _vm.getColumnId(column),
                                        checked: "",
                                      },
                                      domProps: { value: column },
                                      on: {
                                        click: function ($event) {
                                          return _vm.addRemoveColumn($event)
                                        },
                                      },
                                    }),
                                    _vm._v(" " + _vm._s(" " + column) + " "),
                                  ]),
                                ])
                              }
                            ),
                          ],
                          2
                        ),
                      ]),
                      !!_vm.summarySection
                        ? _c("div", [
                            _c(
                              "h4",
                              { staticStyle: { "text-align": "center" } },
                              [_vm._v("Number of rows in evidence tables")]
                            ),
                            _c("label", [
                              _vm._v("Set rows: "),
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.featureRowsNumber,
                                      expression: "featureRowsNumber",
                                    },
                                  ],
                                  staticClass: "number-per-page",
                                  on: {
                                    change: function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.featureRowsNumber = $event.target
                                        .multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "option",
                                    { attrs: { value: "10", selected: "" } },
                                    [_vm._v("10")]
                                  ),
                                  _c("option", { attrs: { value: "15" } }, [
                                    _vm._v("15"),
                                  ]),
                                  _c("option", { attrs: { value: "20" } }, [
                                    _vm._v("20"),
                                  ]),
                                  _c("option", { attrs: { value: "25" } }, [
                                    _vm._v("25"),
                                  ]),
                                  _c("option", { attrs: { value: "30" } }, [
                                    _vm._v("30"),
                                  ]),
                                  _c("option", { attrs: { value: "35" } }, [
                                    _vm._v("35"),
                                  ]),
                                  _c("option", { attrs: { value: "40" } }, [
                                    _vm._v("40"),
                                  ]),
                                  _c("option", { attrs: { value: "45" } }, [
                                    _vm._v("45"),
                                  ]),
                                  _c("option", { attrs: { value: "50" } }, [
                                    _vm._v("50"),
                                  ]),
                                ]
                              ),
                            ]),
                            _c("div", { staticStyle: { color: "red" } }, [
                              _vm._v(
                                "*Updating this value requires rebuilding of the summary table, which may take long time depends on the number of the evidence groups and their data rows."
                              ),
                            ]),
                          ])
                        : _vm._e(),
                    ]),
                  ]
                )
              : _vm._e(),
          ])
        : _vm._e(),
      _c(
        "span",
        { attrs: { id: _vm.sectionId + "_table_wrapper" } },
        [
          !!_vm.dataset
            ? _c("div", {
                staticClass: "table-total-rows",
                domProps: { innerHTML: _vm._s("Total rows: " + this.rows) },
              })
            : _vm._e(),
          _c("div", { staticClass: "table-wrapper" }, [
            !!_vm.dataset && !!_vm.tableFormat
              ? _c(
                  "table",
                  {
                    class: "table table-sm research-data-table " + _vm.pageID,
                    attrs: { cellpadding: "0", cellspacing: "0" },
                  },
                  [
                    _c("thead", {}, [
                      _c(
                        "tr",
                        [
                          !!_vm.tableFormat["star column"]
                            ? _c(
                                "th",
                                { staticClass: "star-items-control" },
                                [
                                  _c("b-icon", {
                                    staticStyle: {
                                      color: "#ffcc00",
                                      cursor: "pointer",
                                    },
                                    attrs: {
                                      icon: !!_vm.stared ? "star-fill" : "star",
                                    },
                                  }),
                                  _c(
                                    "span",
                                    { staticClass: "star-items-options" },
                                    [
                                      _c("ul", [
                                        _c("li", [
                                          _c(
                                            "a",
                                            {
                                              attrs: { href: "javascript:;" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.showHideStared()
                                                },
                                              },
                                            },
                                            [_vm._v("Show stard only")]
                                          ),
                                        ]),
                                        _c("li", [
                                          _c(
                                            "a",
                                            {
                                              attrs: { href: "javascript:;" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.starAll()
                                                },
                                              },
                                            },
                                            [_vm._v("Star / unstar all")]
                                          ),
                                        ]),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          !!_vm.tableFormat["select column"]
                            ? _c("th", { staticClass: "select-items-control" })
                            : _vm._e(),
                          _vm._l(_vm.topRows, function (value, index) {
                            return [
                              _vm.getIfChecked(value) == true &&
                              value !== _vm.tableFormat["select column"]
                                ? _c(
                                    "th",
                                    {
                                      key: index,
                                      staticClass: "byor-tooltip",
                                      class:
                                        "sortable-th " +
                                        value +
                                        " " +
                                        _vm.getColumnId(value),
                                      on: {
                                        click: function ($event) {
                                          !!_vm.multiSectionPage
                                            ? _vm.callFilter(value)
                                            : _vm.applySorting(value)
                                        },
                                      },
                                    },
                                    [
                                      _c("span", {
                                        domProps: { innerHTML: _vm._s(value) },
                                      }),
                                      !!_vm.tableFormat["tool tips"] &&
                                      !!_vm.tableFormat["tool tips"][value]
                                        ? _c("span", {
                                            staticClass: "tooltiptext",
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.tableFormat["tool tips"][
                                                  value
                                                ]
                                              ),
                                            },
                                          })
                                        : _vm._e(),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          }),
                          _vm.tableFormat["features"] != undefined
                            ? _c("th", { staticClass: "th-evidence" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      !!_vm.tableFormat["features header"]
                                        ? _vm.tableFormat["features header"]
                                        : "Evidence"
                                    ) +
                                    " "
                                ),
                              ])
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]),
                    _c(
                      "tbody",
                      {},
                      [
                        _vm._l(_vm.pagedData, function (value, index) {
                          return [
                            _c(
                              "tr",
                              [
                                !!_vm.tableFormat["star column"]
                                  ? _c("td", [
                                      _vm.checkStared("1", value) == false
                                        ? _c(
                                            "span",
                                            [
                                              _c("b-icon", {
                                                staticStyle: {
                                                  color: "#aaaaaa",
                                                  cursor: "pointer",
                                                },
                                                attrs: { icon: "star" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.addStar(value)
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.checkStared("2", value) == true
                                        ? _c(
                                            "span",
                                            [
                                              _c("b-icon", {
                                                staticStyle: {
                                                  color: "#ffcc00",
                                                  cursor: "pointer",
                                                },
                                                attrs: { icon: "star-fill" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.removeStar(value)
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ])
                                  : _vm._e(),
                                !!_vm.tableFormat["select column"]
                                  ? _c("td", [
                                      _c(
                                        "button",
                                        {
                                          attrs: {
                                            disabled: _vm.isSelected(value),
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.selectRow(value)
                                            },
                                          },
                                        },
                                        [_vm._v("Select")]
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._l(value, function (tdValue, tdKey) {
                                  return _vm.topRows.includes(tdKey) &&
                                    _vm.getIfChecked(tdKey) == true &&
                                    tdKey !== _vm.tableFormat["select column"]
                                    ? [
                                        _vm.ifDataObject(tdValue) == false
                                          ? _c(
                                              "td",
                                              {
                                                key: tdKey,
                                                class: _vm.getColumnId(tdKey),
                                              },
                                              [
                                                !!_vm.ifSetParameterColumn(
                                                  tdKey
                                                )
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "set-parameter-options",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              !!_vm.getParameterColumnLabel(
                                                                tdKey
                                                              )
                                                                ? _vm.getParameterColumnLabel(
                                                                    tdKey
                                                                  )
                                                                : tdValue
                                                            ) +
                                                            " "
                                                        ),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "btns-wrapper",
                                                          },
                                                          _vm._l(
                                                            _vm.getParameterTargets(
                                                              tdKey
                                                            ),
                                                            function (section) {
                                                              return _c(
                                                                "button",
                                                                {
                                                                  staticClass:
                                                                    "btn btn-sm show-evidence-btn set-search-btn",
                                                                  domProps: {
                                                                    innerHTML:
                                                                      _vm._s(
                                                                        section.label
                                                                      ),
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.setParameter(
                                                                          tdValue,
                                                                          tdKey,
                                                                          section.section,
                                                                          section.parameter
                                                                        )
                                                                      },
                                                                  },
                                                                }
                                                              )
                                                            }
                                                          ),
                                                          0
                                                        ),
                                                      ]
                                                    )
                                                  : !!_vm.ifSubsectionColumn(
                                                      tdKey
                                                    )
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "dynamic-subsection-options",
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "btns-wrapper",
                                                          },
                                                          [
                                                            _c(
                                                              "button",
                                                              {
                                                                staticClass:
                                                                  "btn btn-sm show-evidence-btn set-search-btn",
                                                                class: {
                                                                  "loaded-subsection":
                                                                    !!_vm.ifSubsectionData(
                                                                      tdKey +
                                                                        tdValue +
                                                                        index
                                                                    ),
                                                                  "loading-subsection":
                                                                    !!_vm.ifSubsectionLoading(
                                                                      tdKey +
                                                                        tdValue +
                                                                        index
                                                                    ),
                                                                },
                                                                attrs: {
                                                                  "data-id":
                                                                    _vm.getRowID(
                                                                      tdKey +
                                                                        tdValue +
                                                                        index
                                                                    ),
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.getSubsectionData(
                                                                        tdValue,
                                                                        tdKey,
                                                                        index
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      !!_vm.getParameterColumnLabel(
                                                                        tdKey
                                                                      )
                                                                        ? _vm.getParameterColumnLabel(
                                                                            tdKey
                                                                          )
                                                                        : tdValue
                                                                    )
                                                                  ),
                                                                ]),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _c("span", {
                                                      domProps: {
                                                        innerHTML: _vm._s(
                                                          _vm.formatValue(
                                                            tdValue,
                                                            tdKey
                                                          )
                                                        ),
                                                      },
                                                    }),
                                                !!_vm.tableFormat[
                                                  "column formatting"
                                                ] &&
                                                _vm.tableFormat[
                                                  "column formatting"
                                                ][tdKey] &&
                                                _vm.tableFormat[
                                                  "column formatting"
                                                ][tdKey].type.includes(
                                                  "copy to clipboard"
                                                )
                                                  ? _c(
                                                      "b-btn",
                                                      {
                                                        staticClass:
                                                          "copy-to-clipboard",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.utils.uiUtils.copy2Clipboard(
                                                              tdValue
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("Copy")]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.ifDataObject(tdValue) == true
                                          ? _c(
                                              "td",
                                              {
                                                key: tdKey,
                                                staticClass: "multi-value-td",
                                                class: _vm.getColumnId(tdKey),
                                              },
                                              _vm._l(
                                                tdValue,
                                                function (
                                                  sValue,
                                                  sKey,
                                                  sIndex
                                                ) {
                                                  return _c(
                                                    "span",
                                                    {
                                                      key: sKey,
                                                      class:
                                                        sKey +
                                                        " reference bg-color-" +
                                                        _vm.getColorIndex(sKey),
                                                    },
                                                    [
                                                      !!_vm.ifSetParameterColumn(
                                                        tdKey
                                                      )
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "set-parameter-options",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    !!_vm.getParameterColumnLabel(
                                                                      tdKey
                                                                    )
                                                                      ? _vm.getParameterColumnLabel(
                                                                          tdKey
                                                                        )
                                                                      : sValue
                                                                  ) +
                                                                  " "
                                                              ),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "btns-wrapper",
                                                                },
                                                                _vm._l(
                                                                  _vm.getParameterTargets(
                                                                    tdKey
                                                                  ),
                                                                  function (
                                                                    section
                                                                  ) {
                                                                    return _c(
                                                                      "button",
                                                                      {
                                                                        staticClass:
                                                                          "btn btn-sm show-evidence-btn set-search-btn",
                                                                        domProps:
                                                                          {
                                                                            innerHTML:
                                                                              _vm._s(
                                                                                section.label
                                                                              ),
                                                                          },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.setParameter(
                                                                                sValue,
                                                                                tdKey,
                                                                                section.section,
                                                                                section.parameter
                                                                              )
                                                                            },
                                                                        },
                                                                      }
                                                                    )
                                                                  }
                                                                ),
                                                                0
                                                              ),
                                                            ]
                                                          )
                                                        : !!_vm.ifSubsectionColumn(
                                                            tdKey
                                                          )
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "dynamic-subsection-options",
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "btns-wrapper",
                                                                },
                                                                [
                                                                  _c(
                                                                    "button",
                                                                    {
                                                                      staticClass:
                                                                        "btn btn-sm show-evidence-btn set-search-btn",
                                                                      class:
                                                                        !!_vm.ifSubsectionData(
                                                                          tdKey +
                                                                            tdValue +
                                                                            index
                                                                        )
                                                                          ? "loaded-subsection"
                                                                          : "",
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.getSubsectionData(
                                                                              tdValue,
                                                                              tdKey,
                                                                              index
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            !!_vm.getParameterColumnLabel(
                                                                              tdKey
                                                                            )
                                                                              ? _vm.getParameterColumnLabel(
                                                                                  tdKey
                                                                                )
                                                                              : tdValue
                                                                          )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          )
                                                        : _c("span", {
                                                            domProps: {
                                                              innerHTML: _vm._s(
                                                                _vm.formatValue(
                                                                  sValue,
                                                                  tdKey
                                                                )
                                                              ),
                                                            },
                                                          }),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            )
                                          : _vm._e(),
                                      ]
                                    : _vm._e()
                                }),
                                _vm.tableFormat["features"] != undefined
                                  ? _c("td", [
                                      !_vm.tableFormat[
                                        "features required column"
                                      ]
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "show-evidence-btn btn",
                                              attrs: { href: "javascript:;" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.showHideFeature(
                                                    "feature_" +
                                                      _vm.sectionId +
                                                      index
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("View")]
                                          )
                                        : _vm.checkFeatureExist(
                                            value,
                                            _vm.tableFormat[
                                              "features required column"
                                            ]
                                          )
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "show-evidence-btn btn",
                                              attrs: { href: "javascript:;" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.showHideFeature(
                                                    "feature_" +
                                                      _vm.sectionId +
                                                      index
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("View")]
                                          )
                                        : _vm._e(),
                                    ])
                                  : _vm._e(),
                              ],
                              2
                            ),
                            !!_vm.tableFormat["features"]
                              ? _c(
                                  "tr",
                                  {
                                    class: "hidden",
                                    attrs: {
                                      id: "feature_" + _vm.sectionId + index,
                                    },
                                  },
                                  [
                                    _c(
                                      "td",
                                      {
                                        staticClass: "features-td",
                                        attrs: { colspan: _vm.topRowNumber },
                                      },
                                      [
                                        _c("research-data-table-features", {
                                          attrs: {
                                            featureRowsNumber:
                                              _vm.featureRowsNumber,
                                            featuresData: value.features,
                                            featuresFormat: _vm.tableFormat,
                                            utils: _vm.utils,
                                            summarySection: _vm.summarySection,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._l(
                              _vm.tableFormat["column formatting"],
                              function (itemValue, itemKey) {
                                return !!_vm.tableFormat["column formatting"]
                                  ? [
                                      itemValue.type.includes(
                                        "dynamic subsection"
                                      ) &&
                                      !!_vm.ifSubsectionData(
                                        itemKey + value[itemKey] + index
                                      )
                                        ? _c(
                                            "tr",
                                            {
                                              key: value[itemKey],
                                              staticClass:
                                                "dynamic-sub-section",
                                              class:
                                                _vm.getRowID(
                                                  itemKey +
                                                    value[itemKey] +
                                                    index
                                                ) +
                                                " " +
                                                _vm.ifHidden(
                                                  itemKey +
                                                    value[itemKey] +
                                                    index
                                                ),
                                            },
                                            [
                                              _c(
                                                "td",
                                                {
                                                  attrs: {
                                                    colspan: _vm.topRowNumber,
                                                  },
                                                },
                                                [
                                                  _c("research-sub-section", {
                                                    attrs: {
                                                      rowId: _vm.getRowID(
                                                        itemKey +
                                                          value[itemKey] +
                                                          index
                                                      ),
                                                      colors: _vm.colors,
                                                      plotMargin:
                                                        _vm.plotMargin,
                                                      subectionConfig:
                                                        itemValue["subsection"],
                                                      subsectionData:
                                                        _vm.collectSubsectionData(
                                                          itemKey +
                                                            value[itemKey] +
                                                            index
                                                        ),
                                                      phenotypeMap:
                                                        _vm.phenotypeMap,
                                                      utils: _vm.utils,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  : _vm._e()
                              }
                            ),
                          ]
                        }),
                      ],
                      2
                    ),
                  ]
                )
              : _vm._e(),
          ]),
          !!_vm.dataset &&
          !!_vm.perPageNumber &&
          _vm.perPageNumber != null &&
          _vm.perPageNumber != 0
            ? _c(
                "b-container",
                { staticClass: "egl-table-page-ui-wrapper" },
                [
                  _c("b-pagination", {
                    staticClass: "pagination-sm justify-content-center",
                    attrs: {
                      "total-rows": _vm.rows,
                      "per-page": _vm.perPageNumber,
                      phenotypeMap: _vm.phenotypeMap,
                    },
                    model: {
                      value: _vm.currentPage,
                      callback: function ($$v) {
                        _vm.currentPage = $$v
                      },
                      expression: "currentPage",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("option", { attrs: { value: "0" } }, [
      _c("span", { staticStyle: { color: "#f00" } }, [_vm._v("All")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("tr", [_c("td", [_c("strong", [_vm._v("Show / hide columns")])])])
  },
]
render._withStripped = true

export { render, staticRenderFns }