var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { ref: "chartWrapper" }, [
    _vm.subsetKey
      ? _c(
          "div",
          {
            ref: "controls",
            staticStyle: {
              display: "flex",
              gap: "10px",
              "justify-content": "flex-end",
              padding: "0 10px",
            },
          },
          [
            _vm.isStacked
              ? _c(
                  "div",
                  {
                    staticClass: "plot-toggle",
                    on: {
                      click: function ($event) {
                        _vm.isNormalized = !_vm.isNormalized
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "plot-toggle-btn",
                        class: `${_vm.isNormalized ? "" : "toggled"}`,
                      },
                      [_vm._v("count")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "plot-toggle-btn",
                        class: `${_vm.isNormalized ? "toggled" : ""}`,
                      },
                      [_vm._v("pct.")]
                    ),
                  ]
                )
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "plot-toggle",
                on: {
                  click: function ($event) {
                    _vm.isStacked = !_vm.isStacked
                  },
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "plot-toggle-btn",
                    class: `${_vm.isStacked ? "" : "toggled"}`,
                  },
                  [_vm._v("group")]
                ),
                _c(
                  "div",
                  {
                    staticClass: "plot-toggle-btn",
                    class: `${_vm.isStacked ? "toggled" : ""}`,
                  },
                  [_vm._v("stack")]
                ),
              ]
            ),
          ]
        )
      : _vm._e(),
    _c("div", { ref: "chart" }),
    _c("div", { ref: "tooltip", staticClass: "tooltip" }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }